import React from "react";
import { graphql } from 'gatsby'

import Header from "../components/Header/Header"
import NewsDetailsPage from '../components/NewsDetails/NewsDetails'
import Footer from "../components/Footer/Footer"

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import "../scss/bootstrap-overrides.scss"
import "../scss/global.scss";
import "../scss/grunticon.scss";

const NewsTemplate = (props) => {
  const GQLNews = props.data.glstrapi?.newsBlog;
  const GQLNewsSimilar = props.data.glstrapi?.newsBlogs;
  const GQLGlobalModule = props.data.glstrapi?.globalModule;
  return(
    <div className="news-details">
      <Header/>
        <NewsDetailsPage newsdata={GQLNews} similarnewsdata={GQLNewsSimilar} globalModule={GQLGlobalModule}/>
      <Footer/>
    </div>
  )
}

export default NewsTemplate


export const pageQuery = graphql`
  query NewsQuery($slug: ID!) {
    glstrapi {
      newsBlog(id: $slug, publicationState: LIVE) {
        id
        Title
        Date
        URL
        Meta_Title
        Meta_Description
        Tile_Image {
          url
          alternativeText
          url_sharp {
            childImageSharp {
              gatsbyImageData(width: 600, breakpoints: 600)
            }
          }
        }
        Short_Description
        Categories {
          Name
          URL
        }
        Banner_Image {
          url
          alternativeText
        }
        Author
        Add_Content {
          ... on GLSTRAPI_ComponentModulesStaticContent {
            Add_Static_Content
          }
          ... on GLSTRAPI_ComponentModulesQuote {
            Add_Quote
          }
          ... on GLSTRAPI_ComponentModulesAddImage {
            Image {
              url
              alternativeText
            }
          }
        }
        imagetransforms
      }
      globalModule {
        Register_Withus_Module_Content
      }
      newsBlogs(where:{id_ne: $slug},limit:3){
        id
        Title
        Date
        URL
        Tile_Image {
          url
          alternativeText
        }
        Categories {
          Name
          URL
        }
        imagetransforms
      }
    }
  }
`